
import { setCursor } from './../index';




export function swupTransitions() {



  const initPage = () => {
    setCursor();
    //FontsLoaded();
    //removeControlsVideo();
  }

  initPage();

}
